<template>
  <div class="company-selection">
    <ElementCard>
      <h2 class="company-selection__header">
        Select a company
      </h2>
      <div class="company-selection__list">
        <a
          v-for="company in companies"
          :key="company.name"
          :href="company.link"
        >
          <ElementCard
            class="company-selection__item"
            shadow="none"
            :title="company.name"
          >
            <div
              class="company-selection__item-image"
              :style="{
                backgroundImage: `url('${company.verticalLogo}')`
              }"
            />

            <div class="company-selection__item-title">
              {{ company.name }}
            </div>
          </ElementCard>
        </a>
      </div>
    </ElementCard>
    <div class="company-selection__logo" />
  </div>
</template>

<script>
import { COMPANIES } from '@/constants/companies'

export default {
  name: 'CompanySelection',

  data: () => ({
    companies: COMPANIES
  })
}
</script>

<style lang="scss" scoped>
.company-selection {
  @include flex-center(column);

  height: 100vh;
}

.company-selection__header {
  text-align: center;
}

.company-selection__list {
  display: flex;
  align-content: center;
  justify-content: space-evenly;
  margin: spacing(2.5) spacing(1.3) spacing(1.3);
}

.company-selection__item {
  position: relative;
  width: spacing(24);
  margin: spacing(2);
  cursor: pointer;

  &:hover {
    box-shadow: $shadow-default;
  }
}

.company-selection__item-image {
  width: 100%;
  height: spacing(17);
  margin-top: spacing(1);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  background-origin: content-box;
}

.company-selection__logo {
  position: absolute;
  bottom: spacing(4);
  width: 100%;
  height: spacing(4);
  background-image: url('~@/assets/images/stitch-logo.svg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  background-origin: content-box;
}

.company-selection__item-title {
  @include text-ellipsis;
  @include text-body-medium;

  margin-top: spacing(4);
}
</style>
